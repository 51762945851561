<template>
  <div class="card p-1">
    <div class="custom-search">
      <div class="title head">
        <p class="h2" style="color: #558cef">รายงานประจำปีแยกตามกลุ่มวัสดุ</p>
      </div>
      <hr />
      <!-- advance search input -->
      <b-row>
        <b-col md="4">
          <b-form-group>
            <label>ปีงบประมาณ:</label>
            <b-form-select
              v-model="selected"
              :options="options"
              placeholder="เลือกปีงบประมาณ"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col md="4">
          <b-form-group>
            <label>กลุ่มงาน:</label>
            <div v-if="equipmentGroup === null">
              <v-select
                v-model="workgroup"
                :options="workgroups"
                label="work_gid_work_gname"
                placeholder="เลือกกลุ่มงาน"
                @input="departments_table(workgroup)"
              />
            </div>
            <div v-else>
              <v-select
                :v-model="(workgroup = null)"
                :options="workgroups"
                label="work_gid_work_gname"
                placeholder="เลือกกลุ่มงาน"
                disabled
              />
            </div> </b-form-group
        ></b-col>
        <b-col md="4">
          <b-form-group>
            <label>ฝ่ายงาน:</label>

            <div v-if="workgroup != null">
              <v-select
                v-model="department"
                :options="departments"
                label="dep_name"
                placeholder="เลือกฝ่ายงาน"
              />
            </div>
            <div v-else>
              <v-select
                :v-model="(department = null)"
                :options="departments"
                label="dep_name"
                placeholder="เลือกฝ่ายงาน"
                disabled
              />
            </div> </b-form-group
        ></b-col> -->
        <b-col md="4">
          <b-form-group>
            <label>กลุ่มวัสดุ:</label>
            <div v-if="workgroup === null">
              <v-select
                v-model="equipmentGroup"
                :options="equipmentGroups"
                label="m_gid_m_gname"
                placeholder="เลือกกลุ่มวัสดุ"
              />
            </div>
            <div v-else>
              <v-select
                :v-model="(equipmentGroup = null)"
                :options="equipmentGroups"
                label="m_gid_m_gname"
                placeholder="เลือกกลุ่มวัสดุ"
                disabled
              />
            </div> </b-form-group
        ></b-col>

        <div class="row col-3 mb-1 mt-2">
          <div class="ml-1">
            <b-button variant="success" @click="get_table_report">
              ค้นหา
            </b-button>
          </div>
          <div class="ml-1">
            <b-button variant="danger"> ยกเลิก </b-button>
          </div>
        </div>
        <!-- <div class="row col-4">
          <div class="col-6">
            <label for="">สถานะ</label>
            <b-form-select v-model="status" :options="statusA" />
          </div>
          <div class="row">
            <div class="ml-1 mt-2">
              <b-button variant="success"> ค้นหา </b-button>
            </div>
            <div class="ml-1 mt-2">
              <b-button variant="danger"> ยกเลิก </b-button>
            </div>
          </div>
        </div> -->
      </b-row>
    </div>

    <!-- table -->
    <b-overlay :show="show" rounded="sm">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        theme="my-theme"
        :line-numbers="true"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'fullName'" class="text-nowrap">
            <b-avatar :src="props.row.avatar" class="mx-1" />
            <span>{{ props.row.fullName }}</span>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'listmenu'">
            <span>
              <b-button
                variant="outline-warning"
                :to="{ name: 'inseakuruplun', params: { id: props.row } }"
              >
                <feather-icon icon="EyeIcon" class="mr-50" />
              </b-button>
            </span>
          </span>
          <span v-else-if="props.column.field === 'จำนวน'">
            <div class="text-right">{{ props.row.จำนวน }}</div>
          </span>
          <span v-else-if="props.column.field === 'ราคาต่อหน่วย'">
            <div class="text-right">{{ props.row.ราคาต่อหน่วย }}</div>
          </span>
          <span v-else-if="props.column.field === 'status'">
            <div v-if="props.row.status === 'รับเข้า'">
              <b-button variant="info">
                {{ props.row.status }}
              </b-button>
            </div>
            <div v-else-if="props.row.status === 'เบิก'">
              <b-button variant="warning">
                {{ props.row.status }}
              </b-button>
            </div>
            <div v-else-if="props.row.status === 'ยืม'">
              <b-button variant="danger">
                {{ props.row.status }}
              </b-button>
            </div>
            <div v-else-if="props.row.status === 'คืน'">
              <b-button variant="success">
                {{ props.row.status }}
              </b-button>
            </div>
          </span>

          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['5', '10', '15', '25', '50', '100']"
                class="mx-1"
                @input="
                  (value) =>
                    props.perPageChanged(
                      { currentPerPage: value },
                      handleChangePage1(value)
                    )
                "
              />
              <span class="text-nowrap">of {{ total }} entries</span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="handlePagechange1($event)"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
  </div>
</template>

<script>
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { VueGoodTable } from 'vue-good-table';
import axios from 'axios';
import API from '@/views/connectDB/condb.js';
export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
  },
  data() {
    return {
      selected: null,
      options: [{ value: null, text: 'เลือกปีงบประมาณ' }],
      equipment_code: '',
      e_number: '',
      serial_number: '',
      e_name: '',
      currentPage: 1,
      pageLength: 100,
      total: '',
      show: false,
      dir: false,
      workgroup: null,
      workgroups: [],
      equipmentGroup: null,
      equipmentGroups: [],
      department: '',
      departments: [],
      columns: [
        {
          label: 'วันที่-เดือน-ปี',
          field: 'req_date',
          filterOptions: {
            enabled: true,
            placeholder: 'ค้นหา วันที่-เดือน-ปี',
          },
        },

        {
          label: 'หมายเลขวัสดุ',
          field: 'm_id',
          filterOptions: {
            enabled: true,
            placeholder: 'ค้นหา หมายเลขวัสดุ',
          },
        },

        {
          label: 'รายการ',
          field: 'm_name',
          filterOptions: {
            enabled: true,
            placeholder: 'ค้นหา รายการ',
          },
        },
        {
          label: 'จำนวนเบิก',
          field: 'req_amount',
          filterOptions: {
            enabled: true,
            placeholder: 'ค้นหา จำนวนเบิก',
          },
        },
        {
          label: 'ผู้เบิก',
          field: 'req_name',
          filterOptions: {
            enabled: true,
            placeholder: 'ค้นหา ผู้เบิก',
          },
        },
        {
          label: 'กลุ่มงาน',
          field: 'work_gname',
          type: 'number',
          filterOptions: {
            enabled: true,
            placeholder: 'ค้นหา กลุ่มงาน',
          },
        },
        {
          label: 'ฝ่ายงาน',
          field: 'dep_name',
          filterOptions: {
            enabled: true,
            placeholder: 'ค้นหา ฝ่ายงาน',
          },
        },

        // {
        //   label: 'รายละเอียด',
        //   field: 'listmenu',
        //   width: '7%',
        // },
      ],
      rows: [],
      searchTerm: '',
      month: [
        '',
        'มกราคม',
        'กุมภาพันธ์ ',
        'มีนาคม ',
        'เมษายน ',
        'พฤษภาคม ',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม',
      ],
    };
  },
  computed: {
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  created() {
    const y = new Date().getFullYear();
    const yy = y + 543;
    this.options.push({ value: yy, text: yy });
    for (let index = 1; index < 5; index++) {
      this.options.push({ value: yy - index, text: yy - index });
    }
    this.workgroups_table();
    this.equipment_table();
  },
  methods: {
    async get_table_report() {
      const yy = this.selected - 543;
      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      if (this.equipmentGroup != null) {
        const url = `${API}reportMaterial?m_gid=${
          this.equipmentGroup.m_gid
        }&startDate=${yy - 1}-09-30&endDate=${yy}-10-01&_limit=100`;
        const res = await axios.get(url, header);
        if (res.data.message.data.length != 0) {
          this.rows = res.data.message.data.map((element) => {
            return {
              ...element,
              req_date: `${parseInt(element.req_date.split('-')[2])} ${
                this.month[parseInt(element.req_date.split('-')[1])]
              } ${Number(element.req_date.split('-')[0]) + 543}`,
            };
          });
          this.total = res.data.message.total;
        } else {
          this.rows = [];
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: `ไม่พบข้อมูล`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }

      if (
        (this.workgroup != null && this.department === null) ||
        this.selected === null
      ) {
        this.department = null;
        this.rows = [];
        if (this.selected === null) {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: `กรุณาเลือกปีงบประมาณ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        if (this.department === null) {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: `กรุณาเลือกฝ่ายงานฝ่ายงาน`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    async equipment_table() {
      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const url = `${API}materialGroups?_page=1&_limit=100`;
      const res = await axios.get(url, header);

      this.equipmentGroups = res.data.message.data.map((element) => {
        return {
          ...element,
          m_gid_m_gname: `${element.m_gid} - ${element.m_gname}`,
        };
      });
    },
    async workgroups_table() {
      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const url = `${API}workgroups?_page=1&_limit=100&_sort=1`;
      const res = await axios.get(url, header);
      this.workgroups = res.data.message.data.map((element) => {
        return {
          ...element,
          work_gid_work_gname: `${element.work_gid} - ${element.work_gname}`,
        };
      });
    },
    async departments_table(value) {
      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const url = `${API}departments?work_gid=${value.work_gid}`;
      const res = await axios.get(url, header);

      this.departments = res.data.message.data;
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, '', {
          headers: {
            Authorization:
              localStorage.getItem('storedData') &&
              JSON.parse(localStorage.getItem('storedData')),
          },
        })
      ).data.message;
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
